<template>
  <div  class="event_div">
    <div v-if=" info.year !== preYear" :class="[currentYear === info.year ? 'select': '']">{{ info.year }}年</div>
    <div class="word">{{ info.word }}</div>
    <!-- <div style="width: 200px;display:">{{ info.entitys }}</div> -->
  </div>
</template>
<script>
// import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'

export default {
  name: 'OneEventLittle',
  props: {
    event_info: Map,
    current_year: Number,
    pre_year: Number
  },
  data () {
    return {
      info: this.event_info,
      currentYear: this.current_year,
      preYear: this.pre_year
    }
  },
  watch: {
    current_year: function (e) {
      this.currentYear = e
    }
  },
  methods: {
    add_select_tags (whichInfo, tag) {
      console.log(tag)
      var groups = []
      var baikeId = this.info.id
      var info = this.info
      if (whichInfo === 'another') {
        baikeId = this.another_info.id
        info = this.another_info
      } else if (whichInfo === 'detail') {
        info = this.detail_info
      }

      if (tag === 'power') {
        groups = info.group_power_selects
      } else if (tag === 'net') {
        groups = info.group_net_selects
      } else if (tag === 'my') {
        groups = info.group_my_selects
      } else if (tag === 'minzu') {
        groups = info.group_minzu_selects
      }
      axios.post('/person_add_groups/', { id: baikeId, groups: groups, tag: tag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
          }
        })
    }
  }
}
</script>

<style scoped>
.event_div{
  cursor:pointer;
  border-radius: 8px;
  /* background: #50b7c1; */
  margin:0px 6px 0px 6px;
  vertical-align: top;
  text-align: left;
  border-bottom: 1px solid rgb(187, 230, 214,0.5);
  color: #ccc;

}
.event_div div{
  vertical-align: top;
  display: inline-block;
}
.year{
  width: 40px;
}
.word{
  width: calc(100% - 50px);
  margin: 0px 10px;
  color: #222;

}
.select{
  color:#67C23A;
}
</style>
