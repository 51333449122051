<template>
<div>
  <HeaderView :page_index="-1" :need_fixed="true"></HeaderView>
  <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 0px 30px 30px 30px; vertical-align: top; padding-top: 90px;" />
  <div v-else class="home"  style="text-align: left; padding-top: 60px;">
    <div style="width: 42%;margin: 30px; text-align: left; display: inline-block; position: fixed;">
      <div id="infoView" >
        <PersonInfoView :person_info="detail_info" />
      </div>
      <div id="listContainer" ref="infoView" :style="'height:' + eventViewHeight + 'px;'">
        <div id="listWrapper"  style="padding: 10px 0px 100px 0px;">
          <div id="yearList" style="padding: 10px 0px 50px 0px;">
            <OneEventLittle  class="listItem"  :event_info="event" :current_year="detail_info.year_info.current" :pre_year= "index == 0 ? -9999 : detail_info.event_list[index-1].year" @click="cilck_one_event(event.year)" v-for="(event, index) in detail_info.event_list"  :key="index"  />
          </div>
        </div>
      </div>
      <PersonCheckView v-if="is_editing && loading" :person_info="detail_info" />
    </div>
    <div class="persons" style="margin-left: 48%;">
      <div class="checkbox"  style="text-align:left; margin: 0px 0px 20px 0px; " v-if=" detail_info.year_info.end > detail_info.year_info.start">
        <div>
          <div style="font-weight: bold; font-size: 32px; vertical-align: middle; display: inline-block;">{{ detail_info.year_info.current }}年 </div>
          <div v-if="!detail_info.year_info.less_start && !detail_info.year_info.less_end" class="year_div"  style="vertical-align: middle; color: #67c23a;">{{ detail_info.year_info.start === detail_info.year_info.current ? '出生' : (detail_info.year_info.current - detail_info.year_info.start) + '岁' }}</div>
          <div v-else-if="detail_info.year_info.less_start && detail_info.year_info.less_end" class="year_div"  style="vertical-align: middle;">人物出生逝世年龄未知</div>
          <div v-else class="year_div"  style="vertical-align: middle;">
            <div style="font-size: 16px;vertical-align: middle; color: #aaa;color: #67c23a;">{{ detail_info.year_info.certitude === 'infer' ? '约' :  detail_info.year_info.certitude === 'less_start' ? '猜' :  detail_info.year_info.certitude === 'less_end' &&  value - detail_info.year_info.start >= 50 ? '或' : ''  }} {{ detail_info.year_info.current  - detail_info.year_info.start }}岁 </div>
            <div style="font-size: 12px;">{{  detail_info.year_info.less_start ? '出生年份未知' :  detail_info.year_info.less_end ? '逝世年份未知' : ''}}</div>
          </div>
        </div>
        <el-slider :show-tooltip="false" v-model="detail_info.year_info.current" :min="detail_info.year_info.start" :max="detail_info.year_info.end" />
          <!-- <el-slider :format-tooltip="(value) => `${detail_info.year_info.certitude === 'infer' ? '约' :  detail_info.year_info.certitude === 'less_start' ? '猜' :  detail_info.year_info.certitude === 'less_end' &&  value - detail_info.year_info.start >= 50 ? '或' : ''}${value-detail_info.year_info.start}岁`"  v-model="detail_info.year_info.current" :min="detail_info.year_info.start" :max="detail_info.year_info.end" /> -->
      </div>
      <div id="listContainer2" style="text-align: left; padding: 0px 0px 10px 0px;">
        <div>
          <div style="color:#ccc; display: inline-block; padding-right: 2px;">当年发生：</div>
          <div style="display: inline-block;"  v-for="(event, index) in detail_info.event_my_list"  :key="index" >
            <div style="padding: 2px 4px; margin: 0px 1px; border:1px solid #50b7c1" v-if="detail_info.year_info.current == event.year_start" >{{ event.name }}{{ event.year_start === event.year_end ? '' : '(' + event.year_start + '-'+ event.year_end + '年)' }}</div>
          </div>
        </div>
        <div>
          <div style="color:#ccc; display: inline-block; padding-right: 2px;">持续进行：</div>
          <div style="display: inline-block;"  v-for="(event, index) in detail_info.event_my_list"  :key="index" >
            <div style="padding: 2px 4px; margin: 0px 1px; border:1px solid #50b7c1" v-if="detail_info.year_info.current >=event.year_start && detail_info.year_info.current <= event.year_end &&  event.year_start !== event.year_end" >{{ event.name }}{{ event.year_start === event.year_end ? '' : '(' + event.year_start + '-'+ event.year_end + '年)' }}</div>
          </div>
        </div>
      </div>

      <PersonListsView :person_info="detail_info" :callback="personLists_Callback"/>
      <div class="checkbox"  style="text-align:left;">
        <OnePersonYear  :person_info="person" :current_year="detail_info.year_info.current" tag="detail" @click="pull_up_another_person_detail(person.id)" v-for="(person, index) in detail_info.person_list3"  :key="index" style="margin-bottom: 6px;" ></OnePersonYear>
      </div>
    </div>

    <!-- <div style="width: 44%;height: 900px;overflow-y: scroll; margin: 30px 30px 30px 10px; text-align: left; vertical-align: top;display: inline-block">
      <OneEventLittle   :event_info="event" :current_year="detail_info.year_info.current" @click="cilck_one_event(event.year)" v-for="(event, index) in detail_info.event_list"  :key="index"  />
    </div> -->
    <AnotherPersonView v-if="another_id"  style="margin: 30px 0px 0px 0px;" :another_id="another_id" :main_person_id="detail_info.id"></AnotherPersonView>
  </div>
  <el-drawer v-model="show.little" :show-close="false" direction="btt" :with-header="false">
    <div style="margin-left: 48%;" v-if="!loading3" class="left_div"><PersonInfoView :person_info="little_info" tag="little"/></div>
  </el-drawer>

</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import PersonInfoView from '@/components/divs/PersonInfoView'
import PersonListsView from '@/components/divs/PersonListsView'
import PersonCheckView from '@/components/divs/PersonCheckView'
import OneEventLittle from '@/components/items/OneEventLittle'
import AnotherPersonView from '@/components/divs/AnotherPersonView'
import OnePersonYear from '@/components/items/OnePersonYear'
// import router from '@/router/index.js'
import HeaderView from '@/components/other/HeaderView'
import { isEditing } from '@/components/config.js'

export default {
  name: 'Person_detail',
  mounted () {
    this.get_index()
  },
  components: {
    PersonInfoView, PersonCheckView, HeaderView, AnotherPersonView, OnePersonYear, OneEventLittle, PersonListsView
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      list: [],
      in_where: null,
      not_in_where: null,
      detail_info: {},
      another_id: null,
      fix: null,
      is_editing: isEditing,
      show: {},
      little_info: {},
      eventViewHeight: 400
    }
  },
  watch: {
    'detail_info.year_info.current': {
      handler (newV, oldV) {
        if (this.loading) {
          return
        }
        this.scroll_event_div()
      }
    }
  },
  created () {
    window.addEventListener('resize', this.updateEventViewHeight)
  },
  methods: {
    get_index () {
      axios.post(Utils.check_is_niming() ? '/person_detail_unlogin/' : '/person_detail/', { id: this.$route.params.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.detail_info = res.data.data.info
          document.title = this.detail_info.word
          this.loading = false
          setTimeout(() => {
            this.updateEventViewHeight()
          }, 80)
        })
    },
    updateEventViewHeight () {
      const infoView = this.$refs.infoView
      if (infoView) {
        const rect = infoView.getBoundingClientRect()
        this.eventViewHeight = window.innerHeight - rect.top - 80
      }
    },
    personLists_Callback (e) {
      // window.open(routeData.href, '_blank')
      // this.another_id = e
      this.loading3 = true
      this.show.little = true
      setTimeout(() => {
        this.little_info = e
        this.loading3 = false
      }, 100)

      // this.show.little = true
      // this.loading3 = true

      // axios.post(Utils.check_is_niming() ? '/person_info_unlogin/' : '/person_info/', { id: e })
      //   .then(res => {
      //     if (res.data.code !== 200) {
      //       Utils.alert(res.data.msg)
      //       return
      //     }
      //     this.little_info = res.data.data.info
      //     this.loading3 = false
      //     console.log(this.little_info)
      //   })
    },
    cilck_one_event (year) {
      this.detail_info.year_info.current = year
      // let minDifference = Infinity;
      // let targetListItem = null;
    },
    scroll_event_div () {
      const currentYear = this.detail_info.year_info.current
      var targetIndex = -1
      if (!this.detail_info || this.loading) {
        return
      }
      for (let i = 0; i < this.detail_info.event_list.length; i++) {
        // console.log(this.detail_info.event_list[i].year, i)
        if (this.detail_info.event_list[i].year === currentYear) {
          targetIndex = i
          break
        }
      }
      if (targetIndex !== -1) {
        const listItems = this.$el.querySelectorAll('#yearList .listItem')
        if (listItems[targetIndex]) {
          listItems[targetIndex].scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}
.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_top{
  border-top:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_right{
  border-right:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_left{
  border-left:  1px solid rgba(0, 0, 0, 0.08);
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor:pointer; */
}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng1{
  /* display: inline-block; */
  /* width: 80px; */
}
li .heng2{
  width: 200px;
}
/* li div span.power_select{
  color:#ffd04b;
  font-size: 20px;
} */
#powers{
  font-size: 14px;
}
#powers  span.power_select{
  color:#50b7c1;
}
#powers  .power_select{
  color:#50b7c1;
}
/* #powers  span.name{
  color:#ffd04b;
  font-size: 20px;
} */
li .num{
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
.info_head{
  vertical-align: middle;
}
.info_head span,.info_head el-button{
  vertical-align: middle;
}
.info_head span{
  line-height: 20px;
}

#listContainer {
    left: 100px;
    top: 0;
    overflow-y: scroll;
    background-color: #fff;
    padding: 10px 0px 50px 0px
  }

  .listItem {
    /* height: 300px; */
    /* background-color: #ddd; */
    margin-bottom: 10px;
  }

  .highlighted {
    background-color: #4CAF50;
  }
  #listWrapper {
    padding: 20px 10px;
  }
.year_div{
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  color: #aaa;
  font-size: 16px;
}
.left_div{
  margin: 10px 100px 50px 0px;
  background: rgba(0, 0, 0, 0);
}
.persons{
  width: 44%;
  margin: 30px 30px 30px 0px;
  text-align: left;
  vertical-align: top;
  display: inline-block
}
</style>
