<template>
  <div   class="login_view" style="text-align: left; vertical-align: middle;  height: 360px; padding: 30px 50px;">
    <div style="width: 250px; font-size: 18px;">
      <ul id="tabs" style="display: inline-block; vertical-align: top; margin-right: 80px;">
        <!-- <div style="color: #ccc; text-align: center;">我们上线了多款APP</div> -->
        <li v-for="(app, index) in app_list"  :key="index" @click="click_tab(index)"  :class="['tab', current_index==index ? 'active' : '']">
          <div v-if="current_index==index">
            <div style="font-weight: bold; font-size: 30px; text-align: center;">{{ app.name }}</div>
          </div>
          <div v-else>
            <div style="font-weight: bold;  color: #50b7c1;">{{ app.name }}</div>
            <div>{{ app.desc }}</div>
          </div>
        </li>
      </ul>
       <div style="display: inline-block;">
        <div style="margin-bottom: 10px;">我们试图把古人代入到他的时代背景和周边人物中，一年一年动态的感受他</div>
        <div style=" color: #50b7c1; margin-bottom: 10px;">可在手机应用商店搜索下载</div>
      </div>
    </div>
    <div style="display: inline-block; text-align: center;" class="right">
      <img  :src="app_list[current_index].img_url" alt="">
      <div class="desc">{{ app_list[current_index].desc }}</div>
      <div>
        <!-- <span class="name">{{ app_list[current_index].name }}</span> -->
      </div>
    </div>
  </div>
</template>
<script>
// import Utils from '@/utils/utils'
// import axios from 'axios'
// import router from '@/router/index.js'
// import store from '@/store'
export default {
  name: 'AppsDialog',
  props: {},
  data () {
    return {
      app_list: [
        { name: '历史年轮', desc: '用动态年龄和向量关系看历史', img_url: 'https://www.fine.today/static/img/logo/yearsmap_512.png' }
        // { name: '花样起名', desc: '用诗词成语拓展起名思路', img_url: 'https://www.fine.today/static/img/logo/ssqm_512.png' },
        // { name: '词向量背单词', desc: '用事物间的关系背单词', img_url: 'https://www.fine.today/static/img/logo/worden_512.png' }
      ],
      current_index: 0
    }
  },
  mounted () {
    this.init_apps()
  },
  methods: {
    init_apps () {
    },
    click_tab (e) {
      this.current_index = e
    }
  }
}
</script>

<style scoped>
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.login_view{
  border-radius: 20px;
  position: relative;
}
.tab {
  margin: 16px 0px;
  width: 200px;
  padding: 10px 10px;
  background: lightblue;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  text-align: left;
}
.active {
  color: #50b7c1;
  /* color: #ffd04b; */
}
.right{
  position: absolute;
  right: 40px;
  top: 40px;
}
.right img{
  width: 280px;
  height: 280px;
  border-radius: 40px;
  margin-bottom: 20px;
}
.right .desc{
  font-weight: bold;
  font-size: 22px;
}
.right div{
  margin: 10px 0px;
}

</style>
