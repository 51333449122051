<template>
  <div>
    <el-menu :default-active="current_index.toString()"
  :ellipsis="false"
  class="el-menu-demo" :style="need_fixed ? 'position: fixed; width: 100%; z-index: 10000;': ''" mode="horizontal" background-color="#545c64" text-color="#fff"  active-text-color="#fff" >
  <!-- class="el-menu-demo" :style="need_fixed ? 'position: fixed; width: 100%; z-index: 10000;': ''" mode="horizontal" background-color="#545c64" text-color="#fff"  active-text-color="#ffd04b"> -->

    <el-menu-item index="0"  @click="handleSelect(0)" >首页</el-menu-item>
    <el-menu-item index="1"  @click="handleSelect(1)" >榜单</el-menu-item>
    <el-menu-item index="2"  @click="handleSelect(2)" >势力</el-menu-item>
    <el-menu-item index="3"  @click="handleSelect(3)" >史料</el-menu-item>
    <el-menu-item index="4"  @click="handleSelect(4)" >搜索</el-menu-item>

    <!-- <el-menu-item index="7"  >系列</el-menu-item>
    <el-menu-item index="7"  >事件</el-menu-item>
    <el-menu-item index="7"  >版图</el-menu-item> -->
    <!-- <div style="display: inline-block;">
      <el-menu-item index="0"  @click="handleSelect(0)" >人物</el-menu-item>
      <el-menu-item index="1"  @click="handleSelect(1)" >搜索</el-menu-item>
    </div> -->
    <div class="flex-grow" />
    <el-menu-item index="9999"  @click="want_load_app()" >APP下载</el-menu-item>

    <!-- <el-menu-item index="6"  @click="login_in()"><el-icon><setting /></el-icon>显示设置</el-menu-item> -->
    <!-- <el-menu-item index="7"   @click="login_in()" >登录</el-menu-item> -->
    <el-menu-item v-if="niming"  @click="want_login_in()" >登录</el-menu-item>
    <el-sub-menu v-else>
      <template #title>我的</template>
      <!-- <el-menu-item index="12-1">显示设置</el-menu-item> -->
      <el-menu-item index="12-2" @click="go_to('Marks')">收藏</el-menu-item>
      <el-menu-item index="12-3" @click="login_out">退出</el-menu-item>
    </el-sub-menu>
    <el-dialog v-model="show_login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view">
      <LoginDialog></LoginDialog>
    </el-dialog>
  </el-menu>

  <el-dialog v-model="show.login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>
  <el-dialog v-model="show.apps"  class="app_view" width="700px"  center  :show-close="false" @closed="close_view"><AppsDialog /></el-dialog>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import axios from 'axios'
import router from '@/router/index.js'
import LoginDialog from '@/components/other/LoginDialog'
import AppsDialog from '@/components/other/AppsDialog'

import { baseUrl } from '@/components/config.js'

export default {
  name: 'HeaderView',
  props: {
    page_index: Number,
    need_fixed: Boolean,
    showLogin: Boolean
  },
  watch: {
    showLogin: function (e) {
      this.show_login = e
    }
  },
  data () {
    return {
      // current_index: this.page_index,
      current_index: -1,
      // need_fixed: this.fixed,
      show: {},
      niming: true
    }
  },
  components: {
    LoginDialog, AppsDialog
  },
  mounted () {
    this.init_x()
  },
  methods: {
    init_x () {
      this.niming = Utils.check_is_niming()
    },
    handleSelect (e) {
      if (e === this.current_index) {
        return
      }
      if (e === 0) {
        router.push({ name: 'Person_index', query: {} })
      } else if (e === 1) {
        router.push({ name: 'Rank_index', query: {} })
      } else if (e === 2) {
        router.push({ name: 'Power_index', query: {} })
      } else if (e === 3) {
        router.push({ name: 'Book_index', query: {} })
      } else if (e === 4) {
        router.push({ name: 'Person_search', query: {} })
      }
    },
    go_to (pageName) {
      router.push({ name: pageName, query: {} })
    },
    close_view () {
      this.show.login = false
    },
    add_idea (e) {
      axios.post('/add_idea/', { content: this.content })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.content = ''
          Utils.alert_success('添加成功')
        })
    },
    login_out (e) {
      axios.post('/login_out/', {})

      localStorage.removeItem('token')
      this.niming = true
      // router.push({ path: '/', query: {} })
      location.href = baseUrl
    },
    want_login_in (e) {
      this.show.login = true
    },
    want_load_app () {
      this.show.apps = true
    }
  }
}
</script>

<style scoped>
ul{
  text-align: right;
}
ul li{
  display: inline-block;
  /* margin: 0px 10px; */
  color: #ccc;
}
ul li a{
  color: lightblue;
}
.login_view{
  border-radius: 20px;
  /* width: 200px;
  height: 200px; */
}
.color_gray{
  background: #bbb;
}
.color_caotou{
  background: #00BFFF;
}
.color_zhengti{
  background: #F08080;
}
.color_baotu{
  background: #48D1CC;
}
.shapes{
  display: inline-block;
  margin: 10px 20px;
  padding: 0px 40px 0px 40px;
  height: 600px;
  overflow: scroll;
}
.shape_div{
  margin: 6px 0px;
  padding: 6px;
  width: 450px;
}
.shape_id{
  margin: 4px 10px;
  font-size: 12px;
  color: lightgreen;
}
.flex-grow {
  flex-grow: 1;
}
.no-header-dialog .el-dialog__header {
  display: none;
}
</style>
