<template>
  <div   class="login_view" style="text-align: center; vertical-align: top;  height: 400px; padding: 30px;">
    <div id="login_container" ></div>
  </div>
</template>
<script>

// import Utils from '@/utils/utils'
// import axios from 'axios'
// import router from '@/router/index.js'
import store from '@/store'

const script2 = document.createElement('script')
script2.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
script2.async = true
document.head.appendChild(script2)

export default {
  name: 'LoginDialog',
  props: {
    show_login: Boolean
  },
  data () {
    return {
      showLogin: true,
      login: true,
      niming: store.state.niming
    }
  },
  mounted () {
    this.initWxLogin()
  },
  methods: {
    initWxLogin () {
      const options = {
        id: 'login_container',
        appid: 'wx1520ab8837a6286e',
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent('https://www.yearsmap.com/login_wechat'),
        style: 'black',
        state: Math.round(Math.random() * 100000)
      }
      const wxLogin = new window.WxLogin(options)
      wxLogin.onSuccess = function (res) {
        // 登录成功后的回调函数
        console.log('登录成功', res)
        // this.login_in(res.code)
      }
      wxLogin.onError = function (res) {
        console.error('登录失败', res)
      }
    }
    // login_in (code) {

    // }
  }
}
</script>

<style scoped>
ul{
  text-align: right;
}
ul li{
  display: inline-block;
  /* margin: 0px 10px; */
  color: #ccc;
}
ul li a{
  color: lightblue;
}
.login_view{
  border-radius: 20px;
  /* width: 200px;
  height: 200px; */
}
.color_gray{
  background: #bbb;
}
.color_caotou{
  background: #00BFFF;
}
.color_zhengti{
  background: #F08080;
}
.color_baotu{
  background: #48D1CC;
}
.shapes{
  display: inline-block;
  margin: 10px 20px;
  padding: 0px 40px 0px 40px;
  height: 600px;
  overflow: scroll;
}
.shape_div{
  margin: 6px 0px;
  padding: 6px;
  width: 450px;
}
.shape_id{
  margin: 4px 10px;
  font-size: 12px;
  color: lightgreen;
}
.flex-grow {
  flex-grow: 1;
}

</style>
